@font-face {
  font-family: 'GeorgiaRegular';
  src: url('../fonts/Georgia-Regular/Georgia.eot');
  src: url('../fonts/Georgia-Regular/Georgia.eot') format('embedded-opentype'),
  url('../fonts/Georgia-Regular/Georgia.woff2') format('woff2'),
  url('../fonts/Georgia-Regular/Georgia.woff') format('woff'),
  url('../fonts/Georgia-Regular/Georgia.ttf') format('truetype'),
  url('../fonts/Georgia-Regular/Georgia.svg#Georgia') format('svg');
}
@font-face {
  font-family: 'GeorgiaBold';
  src: url('../fonts/Georgia-Bold/Georgia-Bold.eot');
  src: url('../fonts/Georgia-Bold/Georgia-Bold.eot') format('embedded-opentype'),
  url('../fonts/Georgia-Bold/Georgia-Bold.woff2') format('woff2'),
  url('../fonts/Georgia-Bold/Georgia-Bold.woff') format('woff'),
  url('../fonts/Georgia-Bold/Georgia-Bold.ttf') format('truetype'),
  url('../fonts/Georgia-Bold/Georgia-Bold.svg#Georgia-Bold') format('svg');
}
@font-face {
  font-family: 'ArialRegular';
  src: url('../fonts/Arial-Regular/ArialRegular.eot');
  src: url('../fonts/Arial-Regular/ArialRegular.eot') format('embedded-opentype'),
  url('../fonts/Arial-Regular/ArialRegular.woff2') format('woff2'),
  url('../fonts/Arial-Regular/ArialRegular.woff') format('woff'),
  url('../fonts/Arial-Regular/ArialRegular.ttf') format('truetype'),
  url('../fonts/Arial-Regular/ArialRegular.svg#ArialRegular') format('svg');
}