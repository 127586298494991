// **********
// Import setting default bootstrap & custom variables
// **********
@import "../settings/setting";

.cookiealert {
  position: absolute !important;
  top: 0;
  bottom: auto;
  border-radius: 0 !important;
  background: $bg-body;
  color: $white;
  transform: translateY(-100%);
  transition: all 800ms ease-out;
  padding: 1rem;
  font-size: .9rem;
  font-family: $ArialRegular;

  p {
    margin: 5px 0 0;
  }

  .sc-Button_Cookie {
    text-align: center;
    @include media-breakpoint-up(sm) {
      float: right
    }

    a {
      color: $white;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }

    .btn-primary {
      background-color: $sc-Button;
      border: 2px solid $white;
      color: $white;
      transition: all 800ms ease-out;
      &:hover {
        background-color: $white;
        border: 2px solid $sc-Button;
        color: $sc-Button;
        transition: all 800ms ease-out;
      }
    }

  }

}