// **********
// Import setting default bootstrap & custom variables
// **********
@import "../settings/setting";

.header-Wrapper {

  .header-Inner_Wrapper {
    position: relative;

    .navbar {
      padding: 0;
    }

    .navbar-brand {
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
      margin-right: 0;
      text-align: center;
      @include media-breakpoint-up(lg) {
        top: 40px;
        right: inherit;
      }

      img {
        background-color: $white;
        border-radius: 10px;
        padding: .5rem;
        @include media-breakpoint-up(lg) {
          border-radius: 0 10px 10px 0;
          padding: .5rem 1rem .5rem 10rem;
        }
        @include media-breakpoint-down(md) {
          width: 50%;
        }
      }

    }

    .sc-Banner_Header {
      @include media-breakpoint-down(sm) {
        height: 600px;
        object-fit: cover;
      }
    }

    .sc-Container_Text-Header {
      position: absolute;
      bottom: 5%;
      left: 0;
      right: 0;
      text-align: center;
      color: $white;
      text-shadow: 2px 2px $black;
      @include media-breakpoint-up(lg) {
        bottom: 10%;
      }

      h1 {
        @include media-breakpoint-down(lg) {
          font-size: 1.5rem;
        }
      }

      .sc-Text {
        position: relative;
        color: $color-text;
        font-size: .9rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.2rem;
          padding: 20px 0 60px;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          display: block;
          background-color: $white;
          width: 50px;
          height: 2px;
          @include media-breakpoint-up(lg) {
            bottom: 30px;
          }
        }
      }

      .sc-Container_Description {
        @include media-breakpoint-down(md) {
          padding: 30px 0 0;
        }
      }

      .sc-Text_Info {
        font-family: $ArialRegular;
        margin: 0;
        font-size: .9rem;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }

    }

  }

}