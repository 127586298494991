* {
  outline: none;
}

html {
  position: relative;
  min-height: 100%;

  body{
    font-family: $GeorgiaRegular;
    margin-bottom: 170px;
    background-color: $bg-service;
    @include media-breakpoint-up(lg) {
      margin-bottom: 145px;
    }
  }

  a {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  p {
    margin: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  strong {
    font-family: $GeorgiaBold;
    font-weight: bold;
  }

}

.page-Wrapper {

  .flex-images {
    width: 100%;
  }

  .sc-Text_Title {
    color: $white;
    padding: 50px 0;
    text-align: center;
    @include media-breakpoint-up(sm) {
      padding: 100px 0 25px;
    }

    h1 {
      @include media-breakpoint-down(md) {
        font-size: 1.5rem;
      }
    }

    .sc-Text {
      position: relative;
      color: $color-text;
      font-size: .9rem;
      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
        padding: 20px 0 70px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        background-color: $white;
        width: 50px;
        height: 2px;
        @include media-breakpoint-up(sm) {
          bottom: 30px;
        }
      }
    }

  }

  .sc-Container_Info {
    font-family: $ArialRegular;
    @include media-breakpoint-down(sm) {
      padding: 0 0 50px;
    }

    .sc-Container_Description {
      padding: 0 0 2rem;

      p {
        margin: 0 0 1rem;
      }

      ul {
        padding: 0 0 0 20px;
        list-style: inside;
      }

      strong {
        font-family: $ArialRegular;
      }

      .sc-Title {
        color: $white;
        font-size: 1.3rem;
        font-weight: bold;
        @include media-breakpoint-up(md) {
          font-size: 1.8rem;
        }
      }

      .sc-Text {
        color: $white;
        font-size: .9rem;
      }

      .sc-Images {
        margin: 0 auto 15px;
        display: block;
        @include media-breakpoint-up(lg) {
          margin: -30px auto 0;
        }
      }

    }

  }

  .breadcrumb {
    font-family: $ArialRegular;
    background-color: transparent;
    margin: 30px 0;
    padding: 0;
    font-size: .9rem;

    a {
      color: $white;
      &:hover, &:focus {
        color: $white;
      }
    }

  }

}


.sc-Whatsapp {
  position: absolute;
  top: -60px;
  left: 50%;
  margin-left: -25px;
  @include media-breakpoint-up(md) {
    top: -20px;
    left: 10px;
    margin-left: 0;
  }

  ul {

    li {
      position: relative;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      font-size: 35px;
      line-height: 48px;
      background-color: $white;
      border: 2px solid $color-whatsapp;
      transition: all .5s ease-in-out;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-whatsapp;
        border-radius: 50%;
        transition: all .5s ease-in-out;
        transform: scale(0);
        z-index: -1;
      }
      &:hover {
        color: $white;
        &:before {
          transform: scale(.9);
        }
      }

      a {
        color: $color-whatsapp;
        display: block;
        &:hover {
          color: $white;
        }
      }

    }

  }

}


#return-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: $colorprimary;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all ease-in-out .4s;
  opacity: .8;
  z-index: 9;
  &:hover {
    bottom: 70px;
    opacity: 1;
  }
  .fa-chevron-up {
    position: relative;
    left: 14px;
    top: 11px;
    color: $white;
    font-size: 1.5rem;
  }
}

//Button Custom

// Main content
.main-Content {

}
