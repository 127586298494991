// Default image path theme
$imgPath: 	                "assets/images/";

// Color system
$colorprimary:              #474167;
$colorsecondary:            #ffffff;

$white:                     #ffffff !default;
$black:                     #000000;
$black-light:               #1A1A1A;
$gray:                      #AFAFAF;
$border-gray:               #D9D9D9;

$backgroud-selection:       $colorprimary;
$color-selection:           $white;

$color-text:                #cbc9c9;


// Font family
$GeorgiaRegular:            'Georgia';
$GeorgiaBold:               'Georgia';
$ArialRegular:              'Arial';


// Layout
$bg-body:                   #3b3b39;
$bg-service:                #2b2b2b;
$color-whatsapp:            #4dc247;


// Opacity
$bg-shadow-container:       rgba(0,0,0,.4);


// Header


// Navbar


// Footer
$background-footer:         #3b3b39;


// Components
$title-gdpr:                #B00000;
$text-gdpr:                 #666666;
$title-antiCorruption:      #000080;
$text-antiCorruption:       #332e4a;
$title-compliance:          #cc1db9;
$text-compliance:           #202951;
$title-certification:       #ff0000;
$text-certification:        #000000;

$text-cookie:               #999999;

$sc-Button:                 #3b3b39;


//***  Specific page  ***//
// Exception


// Homepage

