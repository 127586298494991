// **********
// Import setting default bootstrap & custom variables
// **********
@import "../settings/setting";

.footer-Wrapper {

  .footer-Inner_Wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: $background-footer;
    font-family: $ArialRegular;

    ul {
      text-align: center;
      padding: 35px 0 0;
    }

    li {
      color: $white;
    }

    .sc-Container_Info {
      padding: 15px 0 25px;
      @include media-breakpoint-down(sm) {
        font-size: .9rem;
      }

      p {
        color: $white;
        text-align: center;
      }

    }

  }

}